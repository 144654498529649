import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import Navbar from "../Header/Navbar";
import { Box, Text } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
const Copyright = (props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="Contactus">
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Welcome to Jobs Again HR & Consultants Private Limited
        </Text>
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Copyright
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Jobs Again HR & Consultants Private Limited ("we," "our," "us")
          respects the intellectual property rights of others and expects users
          of our services to do the same. This Copyright Policy explains how we
          address copyright infringement on our website and services.
        </Text>
        <Text fontSize="xx-large" textAlign="left" marginLeft="10px">
          1. Ownership of Content
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          All content and materials available on our website, including but not
          limited to text, graphics, logos, images, audio clips, video clips,
          and software, are the property of Jobs Again HR & Consultants Private
          Limited or its content suppliers and are protected by international
          copyright laws. The compilation of all content on this site is the
          exclusive property of Jobs Again HR & Consultants Private Limited and
          is protected by international copyright laws.
        </Text>
        <Text fontSize="xx-large" textAlign="left" marginLeft="10px">
          2. Use of Content
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          You may access, download, and print materials from our website for
          your personal, non-commercial use only, provided you do not modify or
          delete any copyright, trademark, or other proprietary notices. Any
          other use of the content on our website, including but not limited to
          the reproduction, distribution, modification, display, or transmission
          of the content, without prior written consent from Jobs Again HR &
          Consultants Private Limited, is strictly prohibited.
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          3. Reporting Copyright Infringement
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          If you believe that your work has been copied in a way that
          constitutes copyright infringement, please provide our Copyright Agent
          with the following information in writing:
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of the copyright interest.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          A description of the copyrighted work that you claim has been
          infringed.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          A description of where the material that you claim is infringing is
          located on our website. Your address, telephone number, and email
          address.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          A statement by you that you have a good faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          A statement by you, made under penalty of perjury, that the above
          information in your notice is accurate and that you are the copyright
          owner or authorized to act on the copyright owner's behalf.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Our designated Copyright Agent to receive notifications of claimed
          infringement can be contacted at:
        </Text>

        <Text fontSize="xx-large" textAlign="left" marginLeft="10px">
          Copyright Agent
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Jobs Again HR & Consultants Private Limited
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          [Your Address]
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          [Your City, State, ZIP Code]
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          [Your Email Address]
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          [Your Phone Number]
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          4. Counter-Notification
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          If you believe that your content that was removed (or to which access
          was disabled) is not infringing, or that you have the authorization
          from the copyright owner, the copyright owner's agent, or pursuant to
          the law, to post and use the content, you may send a counter-notice
          containing the following information to our Copyright Agent:
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Your physical or electronic signature. Identification of the content
          that has been removed or to which access has been disabled and the
          location at which the content appeared before it was removed or
          disabled.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          A statement that you have a good faith belief that the content was
          removed or disabled as a result of mistake or a misidentification of
          the content.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Your name, address, telephone number, and email address, a statement
          that you consent to the jurisdiction of the federal court in [Your
          Jurisdiction], and a statement that you will accept service of process
          from the person who provided notification of the alleged infringement.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          If a counter-notice is received by the Copyright Agent, Jobs Again HR
          & Consultants Private Limited may send a copy of the counter-notice to
          the original complaining party informing that person that it may
          replace the removed content or cease disabling it in 10 business days.
        </Text>
        <hr />
        <Footer />
      </div>
    </>
  );
};

export default Copyright;
