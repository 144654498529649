import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import Navbar from "../Header/Navbar";
import { Box, Text } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
const Careers = (props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="Contactus">
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Welcome to Jobs Again HR & Consultants Private Limited
        </Text>
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Careers
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Join Our Team
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Are you passionate about HR and committed to helping individuals and
          businesses succeed? If so, consider joining the team at Jobs Again HR
          & Consultants Private Limited. We are a dynamic and innovative HR
          consultancy firm dedicated to connecting talented professionals with
          exceptional career opportunities and helping businesses build strong
          and effective teams.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Why Work With Us?
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Meaningful Work: At Jobs Again, you'll have the opportunity to make a
          real difference in people's lives by helping job seekers find their
          dream roles and assisting businesses in securing top talent.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong> Professional Growth:</strong> We are committed to investing
          in our team members' professional development and providing
          opportunities for growth and advancement within the company.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Collaborative Environment:</strong> We foster a collaborative
          and supportive work environment where every team member's input is
          valued and respected.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Innovative Solutions:</strong> We encourage creativity and
          innovation in our approach to HR solutions, giving you the chance to
          contribute fresh ideas and make an impact.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Work-Life Balance:</strong> We understand the importance of
          maintaining a healthy work-life balance and offer flexible work
          arrangements to support our team members' well-being.
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          How to Apply
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          To apply for a position at Jobs Again HR & Consultants Private
          Limited, please submit your resume and cover letter to [Email
          Address]. Be sure to include the job title in the subject line of your
          email. Our hiring team will review your application and reach out to
          you if your qualifications align with the position requirements.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Contact Us
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          If you have any questions about career opportunities at Jobs Again HR
          & Consultants Private Limited, please contact us at:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Jobs Again HR & Consultants Private Limited
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          H NO. 450 OLD NO.380 KH NO-619/21 FLAT NO-B-7, VILLAGE CHATTARPUR,
          Sanjay Colony Bhati Mines, South West Delhi, South West Delhi, Delhi,
          India, 110074
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          South West Delhi, Delhi, India, 110074
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          jaswinder.kapoor@gmail.com
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          [Your Phone Number]
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Join us in our mission to empower individuals and businesses to
          achieve their full potential. We look forward to welcoming you to our
          team!
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          This "Careers" page content provides an overview of the company
          culture, benefits of working with Jobs Again HR & Consultants Private
          Limited, current job openings, and instructions on how to apply.
          Adjust any specific details to reflect your company's values
          and job openings.
        </Text>
        <hr />
        <Footer />
      </div>
    </>
  );
};

export default Careers;
