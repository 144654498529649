import React from "react";

const Screen5 = () => {
  return (
    <>
      <div className="screen3">
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <img src="assests/71.jpg" className="img-adjust" />
              </div>
              <div className="col-md-6">
                <img src="assests/64.jpg" className="img-adjust" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <img src="assests/62.jpg" className="img-adjust" />
              </div>
              <div className="col-md-6">
                <img src="assests/63.jpg" className="img-adjust" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src="assests/66.jpg"
              style={{ width: "100%", margin: "10px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Screen5;
