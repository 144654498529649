import React, { useEffect, useState } from "react";
import "./Login.css";
import Screen3 from "../Layout/Screen3";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import particlesConfig from "./../Layout/particlesConfig.json";
import { Box, Center, Container, Text } from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import * as mod from "./../../url";
import axios from "axios";
import Navbar from "../Header/Navbar";

function Register(props) {
  const [init, setInit] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const submitHandler = async () => {
    setLoading(true);

    // Validate user input
    if (!username || !password || !email) {
      toast({
        title: "Please fill all the blanks.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
      return;
    }

    try {
      const requestData = {
        username,
        email,
        password,
      };

      const { data } = await axios.post(
        `${mod.api_url}/auth/signup`,
        requestData
      );
      toast({
        title: "Sign-up Successful.",
        status: "success",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
      navigate("/");
      setEmail("");
      setPassword("");
      setUsername("");
    } catch (error) {
      // Handle errors
      console.error("Registration Error:", error);
      toast({
        title: "Error during Register.",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Navbar />
      <div className="login">
        <Container centerContent>
          <Center>
            <span className="navbar-brand">
              <Link to="/">
                <img
                  src="logo-2.png"
                  style={{ width: "100px", height: "auto" }}
                />
              </Link>
            </span>
          </Center>
          <Text fontSize="xx-large" fontFamily="Work sans" color="white">
            Jobs Again Hr & Consultancy{" "}
            <Text fontSize="large">Admin Regsiter</Text>
          </Text>
        </Container>
        <Container centerContent>
          <Box
            p={3}
            width="100%"
            border="1px solid #e2e8f0"
            borderRadius="10px"
            borderWidth="1px"
          >
            <FormControl id="name" isRequired>
              <FormLabel>Enter Your Name</FormLabel>
              <Input
                placeholder="Enter User Name"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Enter Your Email</FormLabel>
              <Input
                placeholder="Enter Your email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>New Password</FormLabel>
              <InputGroup>
                <Input
                  type={show ? "text" : "password"}
                  placeholder="Enter  Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button
              colorScheme="pink"
              width="100%"
              marginTop={3}
              onClick={submitHandler}
            >
              Register
            </Button>
          </Box>
        </Container>
      </div>
    </>
  );
}

export default Register;
