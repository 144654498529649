import React from "react";
import { Box, Button, Center, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "./../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import "./Pagenotfound.css";

export const Pagenotfound = (props) => {
  const [init, setInit] = useState(false);
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {};
  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <div className="pagenotfound">
        <Center>
          <Box textAlign="center">
            <img
              src="assests/Connection Error.gif"
              alt="page not found"
              width="280px"
            />
          </Box>
        </Center>
        <Text fontSize="26px" mt="4" fontFamily="lemon" fontWeight="600">
          Page Not Found
        </Text>
        <Text mt="2" fontFamily="lemon" fontWeight="600">
          You have entered the wrong page URL. Please go back to the home page.
        </Text>
        <Button as={Link} to="/" colorScheme="red" mt="4" mb={3}>
          Go Back
        </Button>
      </div>
    </>
  );
};
