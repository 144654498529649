import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import Navbar from "../Header/Navbar";
import { Box, Text } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
const Aboutus = (props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="Contactus">
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Welcome to Jobs Again HR & Consultants Private Limited
        </Text>
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          How It Works
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          At Jobs Again HR & Consultants Private Limited, we aim to make your
          job search and recruitment process as seamless and effective as
          possible. Here’s a step-by-step guide on how our services work:
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          For Job Seekers, Create Your Profile
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Sign up on our website and create a detailed profile. Include your
          resume, work experience, education, skills, and job preferences to
          help us match you with suitable opportunities. Explore Job Listings
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Browse through a wide range of job listings from various industries.
          Use our advanced search filters to find roles that match your
          qualifications and interests.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Apply for Jobs
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Apply directly to the jobs that interest you. Our streamlined
          application process ensures your resume and cover letter reach the
          right employers quickly.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Get Matched with Employers
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Our team of experts reviews your profile and matches you with
          employers looking for candidates with your skills and experience. We
          notify you of potential job matches and interview opportunities.
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Interview Preparation
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Receive guidance and resources to prepare for your interviews. We
          offer tips and best practices to help you make a strong impression and
          increase your chances of landing the job. Receive Job Offers
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Once you receive a job offer, we assist you with the negotiation
          process to ensure you get the best possible terms. Congratulations on
          your new job!
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          For Employers
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Register Your Company
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Sign up on our website and create a company profile. Provide details
          about your organization, culture, and the roles you’re looking to
          fill. Post Job Openings
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Post detailed job descriptions and requirements for the positions you
          need to fill. Our platform allows you to reach a wide pool of
          qualified candidates.
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Search for Candidates
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Use our advanced candidate search tools to find professionals who
          match your criteria. Review profiles, resumes, and cover letters to
          identify potential fits.
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Receive Applications
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Manage applications through our user-friendly dashboard. Filter and
          shortlist candidates based on your specific needs.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Schedule Interviews
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Coordinate and schedule interviews with candidates directly through
          our platform. We offer tools to facilitate the interview process,
          making it easy to communicate with applicants. Hire the Best Talent
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Select the best candidates for your roles and extend job offers. Our
          team can assist with offer negotiations and onboarding processes to
          ensure a smooth transition.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Additional Services
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Career Counseling and Coaching
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We offer personalized career counseling and coaching services to help
          job seekers identify their strengths, set career goals, and develop
          strategies for success.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Resume Writing and Optimization
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Our professional resume writers can help you create a compelling
          resume that stands out to employers. We optimize your resume to
          highlight your key skills and achievements.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          HR Consulting for Businesses
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We provide comprehensive HR consulting services to help businesses
          improve their HR practices, from recruitment and onboarding to
          employee retention and development. Why Choose Jobs Again HR &
          Consultants Private Limited?
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Expertise and Experience
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Our team of HR professionals has extensive experience in various
          industries, ensuring we understand your unique needs and challenges.
          Personalized Approach
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We tailor our services to meet the specific needs of both job seekers
          and employers, ensuring the best possible outcomes for everyone
          involved. Commitment to Excellence
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We are dedicated to delivering exceptional service and results. Our
          commitment to quality drives everything we do.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Get Started Today
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Whether you’re a job seeker looking for your next opportunity or an
          employer searching for top talent, Jobs Again HR & Consultants Private
          Limited is here to help. Register now and take the first step towards
          achieving your career goals or building your dream team.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Contact Us
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          For more information or assistance, please contact us at:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Jobs Again HR & Consultants Private Limited
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          H NO. 450 OLD NO.380 KH NO-619/21 FLAT NO-B-7, VILLAGE CHATTARPUR,
          Sanjay Colony Bhati Mines, South West Delhi, South West Delhi, Delhi,
          India, 110074
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          South West Delhi, Delhi, India, 110074
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          jaswinder.kapoor@gmail.com
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          [Your Phone Number]
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          This "How It Works" page content is designed to clearly explain the
          process and services offered by Jobs Again HR & Consultants Private
          Limited. Adjust any specific details to better match your company's
          practices and services.
        </Text>
        <hr />
        <Footer />
      </div>
    </>
  );
};

export default Aboutus;
