import { useContext, useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "./../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import Navbar from "../Header/Navbar";
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Card, CardBody } from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import "./Jobpost.css";
import { SelectedItemContext } from "../SelectedItemContext";

const Jobget = (props) => {
  const [init, setInit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [selectedImage, setSelectedImage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { setSelectedItem } = useContext(SelectedItemContext);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${mod.api_url}/files/all`);
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      toast({
        title: "Facing issue on Get Jobs backend issue",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const filtered = data.filter((item) => {
        const country = item.country ? item.country.toLowerCase() : "";
        const skils = item.skils ? item.skils.toLowerCase() : "";
        const search = searchTerm.toLowerCase();
        return country.includes(search) || skils.includes(search);
      });
      setFilteredData(filtered);
    }
  }, [searchTerm, data]);

  const handleImageClick = (url) => {
    setSelectedImage(url);
    onOpen();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  const handleButtonClickK = (item) => {
    setSelectedItem(item);
    // navigate(`/get-jobs-by-country`); // Adjust the path and parameter as needed
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />

      <div className="jobget">
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          bg="rgb(35, 35, 35)"
          zIndex="1000"
        >
          <Navbar />
        </Box>
        <Box width="100%" height="auto">
          <Container>
            <div className="row" style={{ width: "100%" }}>
              <div className="col-10">
                <Input
                  className="form-control"
                  type="search"
                  placeholder="Search by country or skill"
                  aria-label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ color: "black" }}
                  _placeholder={{ color: "white" }}
                />
              </div>
              <div className="col-2">
                <Button
                  colorScheme="pink"
                  type="button"
                  onClick={() => setSearchTerm("")}
                >
                  Clear
                </Button>
              </div>
            </div>
          </Container>

          {currentItems && currentItems.length > 0 ? (
            currentItems.map((item) => (
              <Card
                key={item._id}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                margin="10px"
              >
                <Image
                  className="jobpost-img"
                  objectFit="cover"
                  maxW={{ base: "100%", sm: "300px" }}
                  maxHeight={{ base: "200px", sm: "200px" }}
                  src={`${mod.api_url}/files/singlePhoto/${item._id}`}
                  alt={item.title}
                  onClick={() =>
                    handleImageClick(
                      `${mod.api_url}/files/singlePhoto/${item._id}`
                    )
                  }
                  cursor="pointer"
                />
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Image Preview</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      {selectedImage && (
                        <Image
                          src={selectedImage}
                          alt="Selected Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                <Stack>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-6">
                        <Heading size="md" textAlign="left">
                          {item.title}
                        </Heading>
                        <Text textAlign="left">
                          <strong>Job Skills:</strong> {item.skils}
                        </Text>
                        <Text textAlign="left">
                          <strong>Job Location:</strong> {item.country}
                        </Text>
                        <Text textAlign="left">
                          <strong>Post Date :</strong>{" "}
                          {item.createdAt.slice(0, 10)}
                        </Text>
                      </div>
                      <div className="col-md-6">
                        <Text textAlign="left">
                          <strong>Job Details:</strong> {item.des.slice(0, 400)}
                        </Text>
                        <Link to="/user-apply-jobs">
                          <Button
                            colorScheme="blue"
                            width="100%"
                            onClick={() => handleButtonClickK(item._id)}
                          >
                            Apply Now
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Stack>
              </Card>
            ))
          ) : (
            <Text>No data available</Text>
          )}
        </Box>
        <Box display="flex" justifyContent="center" mt={4} marginBottom={3}>
          {Array.from({ length: totalPages }, (_, index) => (
            <Button
              key={index}
              onClick={() => paginate(index + 1)}
              colorScheme="pink"
              mx={1}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </Button>
          ))}
        </Box>
      </div>
    </>
  );
};

export default Jobget;
