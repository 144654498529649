import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "./../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import "./Jobpost.css";
import { Link } from "react-router-dom";
import axios from "axios";
import * as mod from "./../../url";
import Navbar from "../Header/Navbar";

const Jobpost = (props) => {
  const [init, setInit] = useState(false);
  const [title, setTitle] = useState("");
  const [country, setCountry] = useState("");
  const [skils, setSkils] = useState("");
  const [des, setDes] = useState("");
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  const SubmitHandler = async () => {
    if (!title || !country || !des || !photo || !skils) {
      toast({
        title: "Please fill all the blanks.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
      return;
    }

    try {
      setLoading(true);

      // Create a FormData object
      const formData = new FormData();
      formData.append("title", title);
      formData.append("skils", skils);
      formData.append("country", country);
      formData.append("des", des);
      formData.append("photo", photo); // Assuming `photo` is a File object

      const response = await axios.post(
        `${mod.api_url}/files/uploadfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response);

      setTitle("");
      setCountry("");
      setPhoto(null);
      setDes("");
      setSkils("");

      toast({
        title: "Job Post Uploaded Successfully.",
        status: "success",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error uploading job post:", error.response || error);

      toast({
        title: "Job Post failed due to some backend issue.",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="jobpost">
        <Container centerContent marginBottom={3}>
          <Center>
            <span className="navbar-brand">
              <Link to="/">
                <img
                  src="logo-2.png"
                  style={{ width: "100px", height: "auto" }}
                />
              </Link>
            </span>
          </Center>
          <Text fontSize="xx-large" fontFamily="Work sans" color="white">
            Jobs Again Hr & Consultancy{" "}
            <Text
              fontSize="large"
              background="hotpink"
              textAlign="center"
              borderRadius="30px"
            >
              Create New Job
            </Text>
          </Text>
          <Box
            p={3}
            width="100%"
            border="1px solid #e2e8f0"
            borderRadius="10px"
            borderWidth="1px"
          >
            <FormControl id="title" isRequired>
              <FormLabel>Enter Job title</FormLabel>
              <Input
                placeholder="Enter Job Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
            </FormControl>
            <FormControl id="skils" isRequired>
              <FormLabel>Enter Job skils</FormLabel>
              <Input
                placeholder="Enter Job Skils"
                onChange={(e) => setSkils(e.target.value)}
                value={skils}
              />
            </FormControl>
            <FormControl id="country" isRequired>
              <FormLabel>Enter Your Country</FormLabel>
              <Input
                placeholder="Enter Your Country"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              />
            </FormControl>
            <FormControl id="file" isRequired>
              <FormLabel>Select Your Image</FormLabel>
              <Input
                type="file"
                padding={1}
                onChange={(e) => setPhoto(e.target.files[0])}
              />
            </FormControl>
            <FormControl id="description" isRequired>
              <FormLabel>Enter Your Description</FormLabel>
              <Input
                placeholder="Enter Your Description"
                onChange={(e) => setDes(e.target.value)}
                value={des}
                type="text"
              />
            </FormControl>

            <Button
              marginTop={3}
              width="100%"
              colorScheme="pink"
              onClick={SubmitHandler}
            >
              Post Job
            </Button>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default Jobpost;
