import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import Navbar from "../Header/Navbar";
import { Box, Text } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
const TermsConditions = (props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="Contactus">
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Welcome to Jobs Again HR & Consultants Private Limited
        </Text>
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Terms & Conditions
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Welcome to Jobs Again HR & Consultants Private Limited ("we," "our,"
          "us"). These Terms & Conditions ("Terms") govern your use of our
          website and services. By accessing or using our services, you agree to
          comply with and be bound by these Terms. If you do not agree with
          these Terms, please do not use our services.
        </Text>
        <Text fontSize="xx-large" textAlign="left" marginLeft="10px">
          1. Use of Our Services
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          1.1 Eligibility:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          You must be at least 18 years old to use our services. By using our
          services, you represent and warrant that you meet this age
          requirement.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          1.2 Account Registration:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          To access certain features of our services, you may need to register
          for an account. You agree to provide accurate, current, and complete
          information during the registration process and to update such
          information to keep it accurate, current, and complete. You are
          responsible for safeguarding your account credentials and for all
          activities that occur under your account.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          1.3 Prohibited Conduct: You agree not to:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Use our services for any illegal purpose or in violation of any local,
          state, national, or international law.
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Post or transmit any content that is unlawful, defamatory, obscene,
          harassing, threatening, or otherwise objectionable.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Impersonate any person or entity or falsely state or otherwise
          misrepresent your affiliation with a person or entity.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Interfere with or disrupt the operation of our services or any servers
          or networks connected to our services.
        </Text>

        <Text fontSize="xx-large" textAlign="left" marginLeft="10px">
          2. Job Listings and Applications
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          2.1 Job Listings:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We provide job listings from third-party employers. While we strive to
          ensure the accuracy and reliability of these listings, we do not
          guarantee their completeness, accuracy, or reliability.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          2.2 Applications:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          When you apply for a job through our services, you authorize us to
          share your application and any related information with the employer.
          We are not responsible for the employer's use of your information.
        </Text>

        <Text fontSize="xx-large" textAlign="left" marginLeft="10px">
          3. Intellectual Property
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          3.1 Ownership:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          All content and materials on our website, including but not limited to
          text, graphics, logos, and software, are the property of Jobs Again HR
          & Consultants Private Limited or its licensors and are protected by
          copyright, trademark, and other intellectual property laws.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          3.2 License:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We grant you a limited, non-exclusive, non-transferable, and revocable
          license to access and use our services for personal, non-commercial
          purposes. You may not reproduce, distribute, modify, or create
          derivative works of our content without our prior written consent.
        </Text>

        <Text fontSize="xx-large" textAlign="left" marginLeft="10px">
          4. Disclaimers and Limitation of Liability
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          4.1 Disclaimers:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Our services are provided "as is" and "as available" without
          warranties of any kind, either express or implied. We do not warrant
          that our services will be uninterrupted, error-free, or free of
          viruses or other harmful components.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          4.2 Limitation of Liability:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          To the fullest extent permitted by law, Jobs Again HR & Consultants
          Private Limited shall not be liable for any indirect, incidental,
          special, consequential, or punitive damages, or any loss of profits or
          revenues, whether incurred directly or indirectly, or any loss of
          data, use, goodwill, or other intangible losses, resulting; <br />
          (i) your use or inability to use our services; <br />
          (ii) any unauthorized access to or use of our servers and/or any
          personal information stored therein;
          <br /> (iii) any interruption or cessation of transmission to or from
          our services;
          <br /> (iv) any bugs, viruses, trojan horses, or the like;
          <br />
          that may be transmitted to or through our services by any third party;
          (v) any errors or omissions in any content or for any loss or damage
          incurred as a result of the use of any content posted, emailed,
          transmitted, or otherwise made available through our services; and/or
          <br />
          (vi) the defamatory, offensive, or illegal conduct of any third party.
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          5. Indemnification
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          You agree to indemnify, defend, and hold harmless Jobs Again HR &
          Consultants Private Limited and its affiliates, officers, directors,
          employees, and agents from and against any claims, liabilities,
          damages, losses, and expenses, including without limitation reasonable
          legal and accounting fees, arising out of or in any way connected with
          your access to or use of our services or your violation of these
          Terms.
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          6. Governing Law
        </Text>

        <Text fontSize="large" textAlign="left" marginLeft="10px">
          These Terms shall be governed by and construed in accordance with the
          laws of [Your Country/State], without regard to its conflict of law
          principles. You agree to submit to the exclusive jurisdiction of the
          courts located in [Your Country/State] to resolve any legal matter
          arising from these Terms or your use of our services.
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          7. Changes to These Terms
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We reserve the right to modify these Terms at any time. We will notify
          you of any changes by posting the new Terms on this page. You are
          advised to review these Terms periodically for any changes. Your
          continued use of our services after the posting of changes constitutes
          your acceptance of such changes.
        </Text>

        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          8. Contact Us
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          If you have any questions about these Terms, please contact us at:
        </Text>

        <Text
          fontSize="large"
          textAlign="left"
          marginLeft="10px"
          marginTop="20px"
        >
          Jobs Again HR & Consultants Private Limited
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          H NO. 450 OLD NO.380 KH NO-619/21 FLAT NO-B-7, VILLAGE CHATTARPUR,
          Sanjay Colony Bhati Mines, South West Delhi, South West Delhi, Delhi,
          India, 110074
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          South West Delhi, Delhi, India, 110074
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          jaswinder.kapoor@gmail.com
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          [Your Phone Number]
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          This template provides a general framework for your Terms &
          Conditions. Be sure to customize it to fit your specific business
          practices and legal requirements. It may also be beneficial to consult
          with a legal professional to ensure compliance with all applicable
          laws and regulations
        </Text>
        <hr />
        <Footer />
      </div>
    </>
  );
};

export default TermsConditions;
