import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import Navbar from "../Header/Navbar";
import { Box, Text } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
const Privacypolicy = (props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="Contactus">
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Welcome to Jobs Again HR & Consultants Private Limited
        </Text>
        <Text fontFamily="lemon" fontWeight="600" fontSize="xx-large">
          Privacy Policy
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Jobs Again HR & Consultants Private Limited ("we," "our," "us") is
          committed to protecting your privacy. This Privacy Policy explains how
          we collect, use, disclose, and safeguard your information when you
          visit our website and use our services. Please read this policy
          carefully to understand our views and practices regarding your
          personal data and how we will treat it.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          1. Information We Collect
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We may collect and process the following types of information about
          you:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Personal Identification Information: Name, email address, phone
          number, postal address, and other contact details.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Professional Information: Resume, employment history, educational
          background, skills, references, and job preferences.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Usage Data: Information about how you use our website and services,
          including IP address, browser type, operating system, referral source,
          and pages viewed.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          2. How We Use Your Information
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We use the information we collect for various purposes, including to:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Provide, operate, and maintain our services. Improve, personalize, and
          expand our services. Understand and analyze how you use our services.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Develop new products, services, features, and functionality.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the service, and for marketing and promotional
          purposes.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Process your job applications and match you with potential employers.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Send you emails and notifications. Find and prevent fraud.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          3. Sharing Your Information
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Employers and Recruiters:</strong> To assist you in finding
          suitable job opportunities.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Service Providers: </strong>Third-party vendors and service
          providers that perform services on our behalf, such as background
          check providers. Business Transfers: In connection with any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Legal Requirements: If required to do so by law or in the good faith
          belief that such action is necessary to comply with legal obligations.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          4. Data Security
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We use administrative, technical, and physical security measures to
          protect your personal information. Despite these efforts, no method of
          transmission over the Internet, or method of electronic storage, is
          100% secure, and we cannot guarantee absolute security.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          5. Your Data Protection Rights
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Depending on your location, you may have the following rights
          regarding your personal data:
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          Access:
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          The right to request copies of your personal data.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Rectification:</strong> The right to request that we correct
          any information you believe is inaccurate or complete information you
          believe is incomplete.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Erasure: </strong>The right to request that we erase your
          personal data, under certain conditions.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Restriction of Processing:</strong>The right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Objection to Processing:</strong>The right to object to our
          processing of your personal data, under certain conditions.
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          <strong>Data Portability: </strong>The right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          6. Third-Party Links
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          Our website may contain links to third-party websites. We are not
          responsible for the privacy practices or the content of those
          websites.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          7. Changes to This Privacy Policy
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page. You
          are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Text>
        <Text fontSize="x-large" textAlign="left" marginLeft="10px">
          8. Contact Us
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          If you have any questions about this Privacy Policy, please contact us
          at:
        </Text>
        <Text
          fontSize="large"
          textAlign="left"
          marginLeft="10px"
          marginTop="20px"
        >
          Jobs Again HR & Consultants Private Limited
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          H NO. 450 OLD NO.380 KH NO-619/21 FLAT NO-B-7, VILLAGE CHATTARPUR,
          Sanjay Colony Bhati Mines, South West Delhi, South West Delhi, Delhi,
          India, 110074
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          South West Delhi, Delhi, India, 110074
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          jaswinder.kapoor@gmail.com
        </Text>
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          [Your Phone Number]
        </Text>{" "}
        <Text fontSize="large" textAlign="left" marginLeft="10px">
          This template provides a comprehensive overview of a privacy policy
          that can be customized to fit your specific needs. Adjust the content
          as necessary to reflect your company's practices and
          legal requirements.
        </Text>
        <hr />
        <Footer />
      </div>
    </>
  );
};

export default Privacypolicy;
