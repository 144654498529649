import { Box, Text } from "@chakra-ui/react";
import React from "react";
import styled from "@emotion/styled";
import { FaArrowRight } from "react-icons/fa";

const Screen7 = () => {
  const Button = styled.button`
    padding: 10px;
    background-color: hotpink;
    font-size: 24px;
    border-radius: 4px;
    height: 55px;
    color: black;
    font-weight: bold;
    &:hover {
      color: white;
    }
  `;

  return (
    <>
      <div className="screen2">
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <Text fontSize="xxx-large" textAlign="center" fontWeight="700">
              Get your business
            </Text>
            <Text
              fontWeight="900"
              fontSize="xxx-large"
              textAlign="center"
              color="hotpink"
            >
              ready for AI{" "}
            </Text>
            <div className="row">
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  Top AI specialists
                </Text>
                <Text fontSize="small" margin="10px">
                  Unlock access to thousands of AI specialists across every
                  technology. Ensure your business thrives in the new AI-powered
                  world.
                </Text>
              </div>
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  Get better results
                </Text>
                <Text fontSize="small" margin="10px">
                  Our freelancers are supercharging their workflows with AI.
                  Evolve your business by combining the best of AI and human
                  talent.
                </Text>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  Build & train own
                </Text>
                <Text fontSize="small" margin="10px">
                  Building a world-class gen-AI product? Tap into our global
                  workforce for expert advice, help training models and much
                  more.
                </Text>
              </div>
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  AI makes it easy
                </Text>
                <Text fontSize="small" margin="10px">
                  Attracting top talent has never been so easy. Just tell us
                  what you need done and our advanced AI will help guide you
                  through the process.
                </Text>
              </div>
            </div>
            <Text fontWeight="800" fontSize="xx-large">
              Is your business AI-ready?{" "}
            </Text>
            {/* <Button>
              <Text
                fontSize="xx-large"
                display="inline-flex"
                alignItems="center"
                color="white"
                fontFamily="cursive"
              >
                Start Now
              </Text>
            </Button> */}
          </div>
          <div className="col-md-6" data-aos="fade-left">
            <img
              src="assests/333.png"
              style={{ width: "100%", margin: "10px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Screen7;
