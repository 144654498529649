import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./Components/Homepage/Home";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import Jobpost from "./Components/JobPost/Jobpost";
import Userdetails from "./Components/userdetailsinput/Userdetails";
import Contactus from "./Components/Contactus/Contactus";
import Jobget from "./Components/JobPost/Jobget";
import Allusers from "./Components/allusers/Allusers";
import Allcontactdetails from "./Components/Allcontactdetails/Allcontactdetails";
import Filterjobs from "./Components/JobPost/Filterjobs";
import Filterjobcountry from "./Components/JobPost/Filterjobcountry";
import Aboutus from "./Components/Pages/Aboutus";
import Privacypolicy from "./Components/Pages/Privacypolicy";
import TermsConditions from "./Components/Pages/TermsConditions";
import Copyright from "./Components/Pages/Copyright";
import Howwework from "./Components/Pages/Howwework";
import Ourteam from "./Components/Pages/Ourteam";
import Careers from "./Components/Pages/Careers";
import HandleJobs from "./Components/JobPost/HandleJobs";
import { Pagenotfound } from "./Components/Pages/pagenotfound";

function App() {
  const [token, setToken] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    const adminInfoString = localStorage.getItem("adminInfo");
    if (adminInfoString) {
      const adminInfo = JSON.parse(adminInfoString);
      setToken(adminInfo.token);
    } else {
      console.error('No adminInfo found in localStorage');
    }
  }, []); // Ensure this runs only once on mount

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/contact-us" element={<Contactus />} exact />
        <Route path="/about-us" element={<Aboutus />} exact />
        <Route path="/privacy-policy" element={<Privacypolicy />} exact />
        <Route path="/get-jobs" element={<Jobget />} exact />
        <Route path="/get-jobs-by-id-filter" element={<Filterjobs />} exact />
        <Route path="/get-jobs-by-country" element={<Filterjobcountry />} exact />
        <Route path="/terms-and-Conditions" element={<TermsConditions />} exact />
        <Route path="/copyright-policy" element={<Copyright />} exact />
        <Route path="/how-we-work" element={<Howwework />} exact />
        <Route path="/meet-with-team" element={<Ourteam />} exact />
        <Route path="/careers" element={<Careers />} exact />
        <Route path="/login" element={<Login />} exact />
        <Route path="/user-apply-jobs" element={<Userdetails />} exact />

        {token ? (
          <>
            <Route
              path="/create-jobs-for-job-seekers-get-the-job-hvevcejhvce-vrev"
              element={<Jobpost />}
              exact
            />
            <Route
              path="/handle-create-jobs-by-a-all-id"
              element={<HandleJobs />}
              exact
            />
            <Route
              path="/all-users-for-check-details"
              element={<Allusers />}
              exact
            />
            <Route
              path="/all-contact-details-for-admin-check"
              element={<Allcontactdetails />}
              exact
            />
            <Route
              path="/register-admin-for-post-jobs"
              element={<Register />}
              exact
            />
          </>
        ) : (
          <Route path="*" element={<Pagenotfound />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
