import { Text } from "@chakra-ui/react";
import React from "react";

const BootamFooter = () => {
  return (
    <>
      <div className="footer">
        <div
          className="row"
          style={{ width: "100%", borderTop: "1px solid white" }}
        >
          <div className="col-md-3">
            <Text>73,000</Text>
            <Text>Registered Users</Text>
          </div>
          <div className="col-md-3">
            <Text>90000</Text>
            <Text>Total Jobs Posted</Text>
          </div>
          <div className="col-md-6">
            <Text></Text>
            <Text>Copyright © 2024 Jobs Again Hr & Consultancy</Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default BootamFooter;
