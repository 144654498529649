import { Image, Text } from "@chakra-ui/react";
import React from "react";

const Screen6 = () => {
  return (
    <>
      <div className="screen6">
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <Text
              color="white"
              textAlign="center"
              fontSize="xxx-large"
              fontWeight="800"
              marginTop="50px"
            >
              Get work done <br />
              <Text color="hotpink">over 2700</Text>
              different <br />
              categories
            </Text>
          </div>
          <div className="col-md-6">
            <Image
              src="assests/4444.png"
              style={{ width: "100%", margin: "10px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Screen6;
