import React, { useEffect } from "react";
import "./Home.css";
import Navbar from "../Header/Navbar";
import { Box, Text } from "@chakra-ui/react";
import Screen1 from "../Layout/Screen1";
import Screen2 from "../Layout/Screen2";
import Screen3 from "../Layout/Screen3";
import Screen4 from "../Layout/Screen4";
import Screen5 from "../Layout/Screen5";
import Footer from "../Footer/Footer";
import Screen6 from "../Layout/Screen6";
import Screen7 from "../Layout/Screen7";
import Screen8 from "../Layout/Screen8";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box width="100%" height="auto">
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          bg="rgb(35, 35, 35)"
          zIndex="1000"
        >
          <Navbar />
        </Box>
        <Screen1 id="particles" />
        <Screen2 />
        <Screen3 />
        <Screen4 />
        <Screen5 />
        <Screen6 />
        <Screen7 />
        <Screen8 />
        <Footer />
      </Box>
    </>
  );
};

export default Home;
