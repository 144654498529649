import { Image, Text } from "@chakra-ui/react";
import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { CiTwitter } from "react-icons/ci";
import BootamFooter from "./BootamFooter";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-3">
            <Link to="/">
              <Image
                // src="logo-2.png"
                src="assests/Jobs-Again-Hr-&-Consultants-logo.png"
                style={{
                  width: "100px",
                  display: "inline-flex",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
              />
            </Link>
            <Text cursor="pointer" marginLeft="20px" textAlign="left">
              Help & Support
            </Text>
            <Text cursor="pointer" marginLeft="20px" textAlign="left">
              South West Delhi, Delhi, India, 110074
            </Text>
            <Text cursor="pointer" marginLeft="20px" textAlign="left">
              jaswinder.kapoor@gmail.com
            </Text>
          </div>

          <div className="col-md-3">
            <Text
              marginLeft="20px"
              fontSize="x-large"
              fontWeight="500"
              cursor="pointer"
              textAlign="left"
            >
              About
            </Text>
            <Link to="/about-us">
              <Text cursor="pointer" marginLeft="20px" textAlign="left">
                About Us{" "}
              </Text>
            </Link>
            <Link to="/privacy-policy">
              <Text cursor="pointer" marginLeft="20px" textAlign="left">
                Privacy Policy
              </Text>
            </Link>
            <Link to="/terms-and-Conditions">
              <Text cursor="pointer" marginLeft="20px" textAlign="left">
                Terms & Conditions
              </Text>
            </Link>
            <Link to="/copyright-policy">
              <Text cursor="pointer" marginLeft="20px" textAlign="left">
                Copyright Policy
              </Text>
            </Link>
          </div>
          <div className="col-md-3">
            <Text
              marginLeft="20px"
              fontSize="x-large"
              fontWeight="500"
              textAlign="left"
              cursor="pointer"
            >
              How we Works
            </Text>
            <Link to="/how-we-work">
              <Text cursor="pointer" marginLeft="20px" textAlign="left">
                How It Works
              </Text>
            </Link>
            <Link to="/meet-with-team">
              <Text cursor="pointer" marginLeft="20px" textAlign="left">
                Meet Our Team
              </Text>
            </Link>
            <Link to="/careers">
              <Text cursor="pointer" marginLeft="20px" textAlign="left">
                careers
              </Text>
            </Link>
          </div>
          <div className="col-md-3">
            <Text
              marginLeft="20px"
              fontSize="x-large"
              fontWeight="500"
              textAlign="left"
            >
              Social Links
            </Text>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginLeft: "25px",
                cursor: "pointer",
              }}
            >
              <FaFacebook
                style={{
                  width: "30px",
                  height: "30px",
                  color: "blue",
                  cursor: "pointer",
                }}
              />
              <FaInstagramSquare
                style={{
                  width: "30px",
                  height: "30px",
                  color: "hotpink",
                  cursor: "pointer",
                }}
              />
              <FaYoutube
                style={{
                  width: "30px",
                  height: "30px",
                  color: "red",
                  cursor: "pointer",
                }}
              />
              <CiTwitter
                style={{
                  width: "30px",
                  height: "30px",
                  color: "red",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <BootamFooter />
    </>
  );
};

export default Footer;
