import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "./../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import Navbar from "../Header/Navbar";
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url";
import "./Jobpost.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SelectedItemContext } from "../SelectedItemContext";

const Filterjobs = (props) => {
  const [init, setInit] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { selectedItem } = useContext(SelectedItemContext);
  const { setSelectedItem } = useContext(SelectedItemContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {};

  const GetSkillJobBySkilName = async () => {
    try {
      console.log("Selected Item:", selectedItem); // Debugging
      const response = await axios.get(
        `${mod.api_url}/files/skils/${selectedItem}`
      );
      setFilteredData(response.data.data);
    } catch (error) {
      toast({
        title: "Facing issue on Get Jobs",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    if (selectedItem) {
      GetSkillJobBySkilName();
    }
  }, [selectedItem]);

  const handleButtonClickK = (item) => {
    setSelectedItem(item);
    // navigate(`/get-jobs-by-country`); // Adjust the path and parameter as needed
  };

  const handleImageClick = (url) => {
    setSelectedImage(url);
    onOpen();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <div className="Filterjobs">
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          bg="rgb(35, 35, 35)"
          zIndex="1000"
        >
          <Navbar />
        </Box>
        {/* <Container> */}
        <Box width="100%" height="auto">
          {filteredData && filteredData.length > 0 ? (
            filteredData.map((item) => (
              <Card
                key={item.id}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                margin="10px"
              >
                <Image
                  objectFit="cover"
                  maxW={{ base: "200px", sm: "300px" }}
                  maxHeight={{ base: "200px", sm: "200px" }}
                  padding={2}
                  src={`${mod.api_url}/files/singlePhoto/${item._id}`}
                  alt={item.title}
                  onClick={() =>
                    handleImageClick(
                      `${mod.api_url}/files/singlePhoto/${item._id}`
                    )
                  }
                  cursor="pointer"
                />
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Image Preview</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      {selectedImage && (
                        <Image
                          src={selectedImage}
                          alt="Selected Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                <Stack>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-6">
                        {" "}
                        <Heading size="md" textAlign="left">
                          {item.title}
                        </Heading>
                        <Text textAlign="left">
                          <strong>Job Skills:</strong> {item.skils}
                        </Text>
                        <Text textAlign="left">
                          <strong>Job Location:</strong> {item.country}
                        </Text>
                        <Text textAlign="left">
                          <strong>Post Date : </strong>
                          {item.createdAt.slice(0, 10)}
                        </Text>
                      </div>
                      <div className="col-md-6">
                        <Text textAlign="left">
                          <strong>Job Details:</strong> {item.des.slice(0, 400)}
                        </Text>
                        <Link to="/user-apply-jobs">
                          <Button
                            colorScheme="blue"
                            width="100%"
                            onClick={() => handleButtonClickK(item._id)}
                          >
                            Apply Now
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Stack>
              </Card>
            ))
          ) : (
            <Text>No data available</Text>
          )}
        </Box>
        {/* </Container> */}
      </div>
    </>
  );
};

export default Filterjobs;
