import React, { createContext, useState, useEffect } from 'react';
export const SelectedItemContext = createContext();

export const SelectedItemProvider = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState(() => {
    const storedItem = localStorage.getItem('selectedItem');
    return storedItem ? JSON.parse(storedItem) : null;
  });

  useEffect(() => {
    if (selectedItem !== null) {
      localStorage.setItem('selectedItem', JSON.stringify(selectedItem));
    }
  }, [selectedItem]);

  return (
    <SelectedItemContext.Provider value={{ selectedItem, setSelectedItem }}>
      {children}
    </SelectedItemContext.Provider>
  );
};
