import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "../Layout/particlesConfig.json";
import { loadSlim } from "@tsparticles/slim";
import "./Contactus.css";
import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import axios from "axios";
import * as mod from "./../../url";
import Navbar from "../Header/Navbar";

const Contactus = (props) => {
  const [init, setInit] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const SubmitHandler = async () => {
    if (!name || !mobile || !email || !message) {
      toast({
        title: "Please fill all the blanks.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    }
    try {
      const response = await axios.post(`${mod.api_url}/contact/submit`, {
        name,
        mobile,
        email,
        message,
      });
      setName("");
      setMobile("");
      setEmail("");
      setMessage("");
      toast({
        title: "Your Quary Send Successfully.",
        status: "success",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Your Data not send ",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Particles
        id={props.id}
        init={particlesLoaded}
        options={particlesConfig}
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        bg="rgb(35, 35, 35)"
        zIndex="1000"
      >
        <Navbar />
      </Box>
      <div className="Contactus">
        <Container centerContent marginBottom={3}>
          <Center>
            <span className="navbar-brand">
              <Link to="/">
                <img
                  src="logo-2.png"
                  style={{ width: "100px", height: "auto" }}
                />
              </Link>
            </span>
          </Center>
          <Text fontSize="xx-large" fontFamily="Work sans" color="white">
            Jobs Again Hr & Consultancy{" "}
            <Text fontSize="large" background="hotpink" borderRadius="30px">
              Contact With us{" "}
            </Text>
          </Text>
          <Box
            p={3}
            width="100%"
            border="1px solid #e2e8f0"
            borderRadius="10px"
            borderWidth="1px"
          >
            <FormControl id="name" isRequired>
              <FormLabel>Enter Your Name</FormLabel>
              <Input
                placeholder="Enter Your Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </FormControl>
            <FormControl id="mobile" isRequired>
              <FormLabel>Enter Your Mobile</FormLabel>
              <Input
                type="tel"
                placeholder="Enter Your Mobile"
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Enter Your Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter Your Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </FormControl>
            <FormControl id="message" isRequired>
              <FormLabel>Enter Your Messgae</FormLabel>
              <Input
                placeholder="Enter Your Message"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
            </FormControl>
            <Button
              marginTop={3}
              width="100%"
              colorScheme="pink"
              onClick={SubmitHandler}
            >
              Send Message
            </Button>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default Contactus;
