import { Box, Text } from "@chakra-ui/react";
import React from "react";
import styled from "@emotion/styled";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const Screen2 = () => {
  const Button = styled.button`
    padding: 10px;
    background-color: hotpink;
    font-size: 24px;
    border-radius: 4px;
    height: 55px;
    color: black;
    font-weight: bold;
    &:hover {
      color: white;
    }
  `;

  return (
    <>
      <div className="screen2">
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <Text fontSize="xxx-large" textAlign="center"></Text>
            <Button>Make It Real </Button>
            <Text fontWeight="900" fontSize="xxx-large" textAlign="center">
              Freelancer{" "}
            </Text>
            <div className="row">
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  The best talent{" "}
                </Text>
                <Text fontSize="small" margin="10px">
                  Discover reliable professionals by exploring their portfolios
                  and immersing yourself in the feedback shared on their
                  profiles.
                </Text>
              </div>
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  Fast bids
                </Text>
                <Text fontSize="small" margin="10px">
                  Get quick, no-obligation quotes from skilled freelancers. 80%
                  of jobs receive bids within 60 seconds. Your idea is just
                  moments from reality.
                </Text>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  The best talent{" "}
                </Text>
                <Text fontSize="small" margin="10px">
                  Discover reliable professionals by exploring their portfolios
                  and immersing yourself in the feedback shared on their
                  profiles.
                </Text>
              </div>
              <div className="col-6">
                <Text fontSize="xx-large" textAlign="center">
                  Fast bids
                </Text>
                <Text fontSize="small" margin="10px">
                  Get quick, no-obligation quotes from skilled freelancers. 80%
                  of jobs receive bids within 60 seconds. Your idea is just
                  moments from reality.
                </Text>
              </div>
            </div>
            <Text fontWeight="800" fontSize="xx-large">
              Make your dreams a reality.
            </Text>
            <Box>
              <Link to="/contact-us">
                <Button
                  fontSize="xx-large"
                  display="inline-flex"
                  alignItems="center"
                  color="hotpink"
                  fontFamily="cursive"
                >
                  Get Started Now
                </Button>
              </Link>
            </Box>
          </div>
          <div className="col-md-6" data-aos="fade-left">
            <img
              src="./../assests/000002.png"
              style={{ width: "100%", margin: "10px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Screen2;
